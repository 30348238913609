.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

/* .navbar {
  --bs-navbar-toggler-padding-y: 0;
  --bs-navbar-toggler-padding-x: 0;
  --bs-navbar-toggler-font-size: 1.5rem;
  --bs-navbar-toggler-border-color: white;
  --bs-navbar-toggler-border-radius: 0;
  --bs-navbar-toggler-focus-width: 0;
  --bs-navbar-toggler-transition: none;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
} */

.navbar .navbar-toggler {
    color: rgba(255, 255, 255, 0.1) !important;
    border-color: rgba(255, 255, 255, 1) !important;
}

.navbar::after {
    background-color: transparent;
}

.activation_code::placeholder {
    text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

/* #qr-video {
    width: 30%;
    height: 30%;
    position: relative;
    display: inline-block;
} */
/* The scrollbar track (the background) */
.base-card-scrollable {
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
    max-height: 35%;
}

.base-card-scrollable::-webkit-scrollbar {
    width: 6px; /* width of the entire scrollbar */
    height: 6px; /* height of the entire scrollbar */
}

/* The scrollbar handle */
.base-card-scrollable::-webkit-scrollbar-thumb {
    background-color: rgb(80, 77, 77); /* color of the scrollbar itself */
    border-radius: 6px; /* roundness of the scrollbar */
    border: 1px solid rgb(77, 75, 75); /* creates a border around the scrollbar */
}

/* The scrollbar track (the background) */
.base-card-scrollable::-webkit-scrollbar-track {
    background: white; /* color of the track */
    border-radius: 6px; /* roundness of the track */
}

/* Optional: for hover effects */
.base-card-scrollable::-webkit-scrollbar-thumb:hover {
    background: rgb(157, 155, 155); /* color when hovering over the scrollbar */
}

.red-light {
    fill: url(#red-gradient);
}
