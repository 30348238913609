@font-face {
    font-family: 'Montserrat';
    src: url('./assets/Montserrat-Medium.ttf') format('truetype');
    /* Adjust the path as needed */
}

@font-face {
    font-family: 'Montserratbold';
    src: url('./assets/Montserrat-Bold.ttf') format('truetype');
    /* Adjust the path as needed */
}

@font-face {
    font-family: 'Montserratlight';
    src: url('./assets/Montserrat-Light.ttf') format('truetype');
    /* Adjust the path as needed */
}

/* Use the font in a class or element */
.body-text {
    font-family: 'Montserratlight', sans-serif;
}

body {
    margin: 0;
    font-family:
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        'Montserrat',
        'Montserratlight',
        'Montserratbold' sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
